import axios from "axios";
class TelegramService {
    constructor(endpoint){
        this.endpoint = 'https://api.telegram.org:443/bot1487789782:AAHCcPS6SJDv0m8CQpz09h81yZw6t1S_wcA/sendMessage'
    }
    
    async sendMessage(user, data){
        const text0 = `********CONSULTA LEGISAPP********`;
        const text1 = `👤 Usuario: ${user.name} ${user.lastname}\n✉️ Email: ${user.email}\n`;
        const text2 = `📱 Telefono: ${data.phone}\n`;
        const text3 = `✏️ Comentario: ${data.comment}\n`;
  
        const url = this.endpoint
  
        const params = {
          chat_id: '213077083',
          text: `${text0}\n${text1}${text2}${text3}`,
          parse_mode: "HTML",
        };
  
        const result = await axios.post(url, params)

        return result.data
    }

    async trelloMessage(user, data){
        const token = '714066bbab13f03f74bd3702b13c142f8e3b43f67da177ffd7e34aabfd3f9be1';
        const key = '363fb1c6c7424265542573f2cd3def74';
        const idBoard = '630dfb338f1717011fbca4b2';
        const idList = '630dfb482892df03a411794c';

        //const urlTags = `https://api.trello.com/1/boards/${idBoard}/labels?key=${key}&token=${token}`;
        // Realiza la llamada a la API de Trello para obtener las etiquetas si es necesario

        const body = `Nombre: ${user.name} ${user.lastname}\n
                    Email: ${user.email}\n
                    Teléfono: ${data.phone}\n
                    Mensaje: ${data.comment}`;

        const parameters = {
        name: `Nuevo Ticket [LEGISAPP]`,
        desc: body,
        pos: 'top',
        due: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        idLabels: '630dfb33d6e15900043e6391',
        idBoard: idBoard,
        };

        const url = `https://api.trello.com/1/cards?idList=${idList}&key=${key}&token=${token}`;

        try {
        const response = await axios.post(url, parameters);

        if (response.status === 200) {
            // Realiza la llamada a tu webhook de Discord si es necesario

            // Muestra el mensaje de éxito al usuario
            return true;
        }
        } catch (error) {
            console.error('Error al enviar el mensaje:', error);
            // Maneja el error de manera apropiada, por ejemplo, mostrando un mensaje de error al usuario
        }
    };

    }


export default new TelegramService()