import React, { useEffect, useState } from 'react';
import AlertComponent from './AlertComponent';

function AlertaSistemas() {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const key = 'AIzaSyADukn7uRD52osQoHWiOwvHyretAb4B0RE';
      const idSheet = '1dBfhPoTuo4RBcmtoa3JVLoomBEXmvT3-wZkefMzzZTw';
      const range = 'legisapp!A2:D';
      const sheetV4Uri = `https://sheets.googleapis.com/v4/spreadsheets/${idSheet}/values/${range}?key=${key}`;

      try {
        const response = await fetch(sheetV4Uri);
        const data = await response.json();

        
        const filterActive = data.values.filter(item => item[3] === '1');
        setFilteredData(filterActive);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  return (
    <div>
      {/* Render your filtered data here */}
      {filteredData.map((item, index) => (
        <AlertComponent key={index} title={item[0]} variant={item[2]} message={item[1]} time='hoy' />
      ))}
    </div>
  );
}

export default AlertaSistemas;