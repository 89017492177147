import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCPVYTMQ4eplAOzqxDQQ3IQjo7DQIAosj4",
  authDomain: "legisappnew.firebaseapp.com",
  projectId: "legisappnew",
  storageBucket: "legisappnew.appspot.com",
  messagingSenderId: "227876426746",
  appId: "1:227876426746:web:7126e2ce12ff8bb8964a43"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export { db };
