import LoginLayout from "../layout/LoginLayout"

import { Link, useNavigate } from "react-router-dom"
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

import { IoLogInOutline, IoHelpBuoyOutline } from "react-icons/io5";

import { useContext } from "react";
import { AuthContext } from '../auth/authContext';
import AuthenticationService from "../services/AuthenticationService";
import { getMobileSystem } from "../helpers/getMobileSystem";

const Login = () => {

    const { dispatch } = useContext(AuthContext)

    const navigate = useNavigate()

    const dispositivo = getMobileSystem();
    
    const logOn = () => {
        
        const action = AuthenticationService.logOn()

        if(typeof action === 'object'){
            dispatch(action)
            navigate('/', { replace: true })
        }else{
            window.location = action
        }    
    }

    return(
        <>
            <LoginLayout>
                <Container>
                    <Row className="justify-content-center text-center mt-4">
                        <Col xs={10} sm={6}>
                            <img src="LegisApp.svg" alt="LegisApp" className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center m-4">
                        { dispositivo === 'iOS' || dispositivo === 'Android' ? 
                        <Col xs={12} sm={8} md={6}>
                            <Card className="mb-4 shadow-sm border-gray animate__animated animate__fadeIn">
                                <Card.Body>
                                    <Card.Title className="fw-light text-center m-4">
                                        <IoLogInOutline size={60} />
                                    </Card.Title>
                                    <div className="d-grid gap-2 mb-4">
                                        <Button onClick={logOn} className="btn-legis" size="lg">Ingresar</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        :
                        <Col xs={12} sm={8} md={6}>
                            <Card className="mb-4 shadow-sm border-gray animate__animated animate__fadeIn">
                                <Card.Body>
                                    <Card.Title className="fw-light text-center m-4">
                                        <IoLogInOutline size={60} />
                                    </Card.Title>
                                    <div className="d-grid gap-2 mb-4">
                                        <Button onClick={logOn} className="btn-legis" size="lg">Ingresar</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        // <Col xs={12} sm={8} md={6}>
                        //     <Card className="mb-4 shadow-sm border-gray animate__animated animate__fadeIn">
                        //         <Card.Body className="text-center">
                        //             <p>Ingresá a LegisAPP desde tu celular escaneando el código QR ó ingresando a <b>https://app.legislatura.gob.ar</b></p>
                        //             <img width={'50%'} src="qr.png"></img>
                        //         </Card.Body>
                        //     </Card>
                        // </Col>
                        }
                    </Row>
                    <Row className="justify-content-center mb-4">
                        <Col xs={6}>
                            <p className="text-center">
                                <IoHelpBuoyOutline /><br />
                                <Link to="/soporte" className="link-dark text-decoration-none fw-light">Soporte</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </LoginLayout>
        </>
    )
}

export default Login