import {Container, Row, Col, ListGroup} from 'react-bootstrap'
import {FaTwitter, FaFacebookF, FaInstagram, FaYoutube} from 'react-icons/fa'

const FooterLayout = () => {
    return(
        <>
            <footer className='footer-login'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Row className='justify-content-center mb-4'>
                                <Col xs={12} className="text-center">
                                    <img src='/logo_sistemas.png' alt='Logotipo' height={50} />
                                </Col>
                                <Col xs={12}>
                                    <div className='border-top border-gray mt-3 mb-3'></div>
                                    <Row>
                                        <Col xs={6} xm={4} md={2} className="text-end">
                                            <ListGroup horizontal>
                                                <ListGroup.Item className='border-0' action target='_blank' href="http://www.facebook.com/LegisCABA"><FaFacebookF size={20} /></ListGroup.Item>
                                                <ListGroup.Item className='border-0' action target='_blank' href="https://www.instagram.com/legiscaba"><FaInstagram size={20} /></ListGroup.Item>
                                                <ListGroup.Item className='border-0' action target='_blank' href="https://twitter.com/legisCaba"><FaTwitter size={20} /></ListGroup.Item>
                                                <ListGroup.Item className='border-0' action target='_blank' href="https://www.youtube.com/user/LegislaturaCABA"><FaYoutube size={20} /></ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                        <Col>
                                            <span className='d-block pt-2 fw-bold text-end'>@LegisCABA <small style={{fontSize:'.6rem'}}>v 1.4.2</small></span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default FooterLayout